.sidebar-container {
    background-color: #535353;
    width: 27rem;

    z-index: 2000;

    box-sizing: border-box;
    max-height: calc(100vh - 2.2rem - 1.15rem - 2px);
    min-height: 400px;
    display: flex;
    flex-direction: column;

    border: 1px solid #383838;
}

.sidebar-tabs {
    height: calc( 2.3rem + 1px );
    display: flex;
    max-width: 100%;
    box-sizing: border-box;
    background-color: #424242;

    border: 0 solid #383838;
    border-width: 0 0 1px 0;
}

.sidebar-tabs-option-container {
    overflow: hidden;
}

.sidebar-tabs-option-container--active {
    overflow: visible;
}

.sidebar-tabs-option-container--active > .sidebar-tabs-option {
    background-color: #535353;
    cursor: auto;
}

.sidebar-tabs-option {
    height: 100%;
    padding: 0 0.5rem 1px 0.5rem;
    background-color: #424242;
    display: inline-block;

    user-select: none;
    -moz-user-select: none;
    cursor: pointer;

    border-right: 1px solid #383838;

    font-family: "Lato", sans-serif;
    color: #f0f0f0;
    line-height: 2.3rem;
    text-align: center;
    white-space: nowrap;

    flex-shrink: 0;
}

.sidebar-view-container {
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
}

.sidebar-input-container {
    height: 1.6rem;

    display: flex;
    justify-content: space-between;
}

.sidebar-inputs-divider {
    height: 1px;
    width: 100%;
    margin: 15px 0;
    background-color: #424242;
}

.sidebar-input-label {
    max-width: 45%;
    font-size: 0.85rem;
    line-height: 1.8rem;
}

.sidebar-input {
    max-width: 55%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1rem;
    line-height: 1.4rem;
}

.sidebar-input > input {
    min-width: 0;
}

.sidebar-input--readonly {
    height:100%;
    line-height: 1.7rem;
    margin-right: 2px;
}

@media (max-width: 960px)
{
    .sidebar-container {
        height: 100%;
        min-height: 100%;
        width: 100vw;
        min-width: 100vw;

        position: absolute;
        top: 0;
        right: -100vw;
        z-index: 10000;

        transition: right 0.3s ease-in-out;
    }

    .sidebar-container.drawer {
        right: 0;
    }

    .sidebar-tabs-mobileClose {
        padding-right: 10px;
    }

    .sidebar-tabs-mobileClose svg {
        fill: #f0f0f0;
        height: calc(100% - 1px);
    }
}