/*
 * container
 */

.menu-container {
    height: 2.2rem;
    padding: 0 46px;
    background-color: var(--color-5);
}

.menu {
    height: 100%;
    display: flex;
    align-items: center;

    user-select: none;
    -moz-user-select: none;
}

@media (max-width: 960px)
{
    .menu-container {
        position: absolute;
        z-index: 2000;
        height: 100%;
        width: 240px;
        padding: 0;
        top: 0;
        left: -240px;

        transition: left 0.3s ease-in-out;
    }

    .menu-container.drawer {
        left: 0;
    }

    .menu-container:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 240px;
        height: 100%;
        width: 100vw;
        background-color: rgba(0,0,0,0);
        pointer-events: none;

        transition: background-color 0.3s ease-in-out;
    }

    .menu-container.drawer:after {
        background-color: rgba(0,0,0,0.5);
        pointer-events: all;
    }

    .menu > .flex-filler {
        min-height: 32px;
    }

    .menu {
        flex-direction: column;
        overflow-y: auto;
        align-items: stretch;
        box-sizing: border-box;
        padding-bottom: 24px;
    }

    .menu-logo {
        width: 100%;
        min-height: 56px;
        border: 0 solid #383838;
        border-bottom-width: 1px;
        margin-bottom: 24px;
    }

    @media screen and (orientation:landscape) {
        .menu-logo {
            min-height: 40px;
        }
    }
}

/*
 * top links
 */

.menu-link.--support {
    margin-right: 0.5rem;
}

.menu-link.--support .input-link {
    border: 1px solid #db61a2;
    background-color: var(--color-6);
}

.menu-link.--support .input-link:hover, .menu-link.--support .input-link:focus {
    border: 1px solid #db61a2;
    background-color: var(--color-6);
    box-shadow: 1px 1px pink;
}

.menu-link.--support .input-link:active {
    box-shadow: 1px 1px #db61a2;
}

.menu-link.--support.--highlight .input-link {
    border: 1px solid red;
    transition: border-color 0.7s ease-out;
}

.menu-link.--support .heart {
    margin-right: 0.2rem;
    font-size: 1.3rem;
    filter: drop-shadow(2px 2px 5px #222);
    position: relative;
    bottom: 0.5rem;
    opacity: 0;
    transition: opacity 0.7s ease-out, bottom 0.7s ease-out;
}

.menu-link.--support.--highlight .heart {
    bottom: 0;
    opacity: 1;
}

@media (max-width: 960px)
{
    .menu-link.--support {
        margin: 0 0 10px;
        text-align: center;
    }

    .menu-link.--support .input-label {
        line-height: 21px;
        min-height: 21px;
    }

    .menu-link.--support .input-link {
        margin: 5px 12px 5px 12px;
        padding: 8px 25px 9px;
        box-shadow: 0 0 10px 1px #db61a2;
        border: 1px solid transparent;
    }

    .menu-link.--support .input-link:hover, .menu-link.--support .input-link:focus {
        border: 1px solid #db61a2;
        box-shadow: 0 0 8px 2px #db61a2;
    }

    .menu-link.--support .input-link:active {
        transition: box-shadow 0.2s ease;
        box-shadow: none;
    }

    .menu-link.--support.--highlight .input-link {
        border: 1px solid transparent;
        box-shadow: none;
        background-color: transparent;
        transition: background-color 0.7s ease-out, box-shadow 0.7s ease-out;
    }

    .menu-link.--support.--highlight {
        transition: background-color 0.7s ease-out;
        background-color: rgba(255,0,0,0.2);
        position: relative;
    }

    .menu-link.--support .heart {
        position: absolute;
        margin: 0 0 15px 9px;
    }

    .menu-link.--support.--highlight .heart {
        bottom: 0;
        opacity: 1;
    }
}

/* == */

.menu-link .input-link {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

.menu-link .input-link:hover {
    box-shadow: none;
    background-color: var(--color-6);
    border-color: var(--color-4);
}

.menu-link .input-link:focus {
    box-shadow: none;
    border-color: var(--color-4);
}

.menu-link .input-link:active,
.menu-link.--active .input-link,
.menu-link.--active:hover .input-link {
    background-color: var(--color-7);
    border-color: var(--color-4);
}

.menu-link .input-label {
    font-size: 0.95rem;
    letter-spacing: initial;
}

@media (max-width: 960px)
{
    .menu-link {
        justify-content: flex-start;
    }

    .menu-link .input-link {
        height:auto;
        width: 100%;
        border: none;
        padding: 13px 10px 13px 24px;
        -webkit-tap-highlight-color: transparent;
    }

    .menu-link .input-link:hover,
    .menu-link.--active .input-link:hover,
    .menu-link .input-link:focus,
    .menu-link.--active .input-link {
        background-color: var(--color-4);
    }

    .menu-link.--active .input-link:focus {
        /* --color-3 too bright */
        background-color: rgba(255, 255, 255, 0.2);
    }

    .menu-link .input-label {
        margin-left: 0;
        font-size: 15px;
        white-space: pre-wrap;
    }
}

/*
 * dropdown
 */

.menu-dropdown {
    position: absolute;
    z-index: 5000;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 10rem;
    padding: 0.15rem;
    outline: none;

    background-color: var(--color-1);
    border: 1px solid var(--color-2);
}

.menu-dropdown.--nested {
    left: calc(100% - 0.2rem);
    top: -0.2rem;
}

.menu-dropdown-item {
    position: relative;
    padding: 0.25rem 1.3rem 0.35rem 2rem;
    text-align: left;
    font-size: 0.93rem;
    color: black;
    white-space: nowrap;
    cursor: pointer;
    -moz-cursor: pointer;
}

.menu-dropdown-item:hover, .menu-dropdown-item:focus {
    outline: none;
    background-color: #90c8f6;
}

.menu-dropdown-item.--divider {
    min-height: 1px;
    background-color: var(--color-2);
    padding: 0;
    margin: 0.25rem 0 0.2rem 1.85rem;
    cursor: default;
    -moz-cursor: default;
}

.menu-dropdown-item.--disabled {
    cursor: default;
    -moz-cursor: default;
    color: var(--color-4);
}

.menu-dropdown-item.--disabled:hover, .menu-dropdown-item.--disabled:focus {
    background-color: var(--color-2);
}

.menu-dropdown-item.--menu {
    --icon-black:   url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(51, 51, 51)\" d=\"M14.54,11.8L14.54,11.8l-4.03,4.44l-0.74-0.67l3.38-3.72l-3.7-3.36l0.67-0.74l4.44,4.03L14.54,11.8z\"/></svg>");
    --icon-grey:    url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(120, 120, 120)\" d=\"M14.54,11.8L14.54,11.8l-4.03,4.44l-0.74-0.67l3.38-3.72l-3.7-3.36l0.67-0.74l4.44,4.03L14.54,11.8z\"/></svg>");
    --icon-white:   url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(250, 250, 250)\" d=\"M14.54,11.8L14.54,11.8l-4.03,4.44l-0.74-0.67l3.38-3.72l-3.7-3.36l0.67-0.74l4.44,4.03L14.54,11.8z\"/></svg>");

    cursor: default;
    -moz-cursor: default;
}

.menu-dropdown-item.--menu::after {
    position: absolute;
    display: block;
    content: var(--icon-black);
    height: 100%;
    width: 1.5rem;
    right: 0;
    top: 0.035rem;
}

.menu-dropdown-item.--menu.--disabled::after {
    content: var(--icon-grey);
}

.menu-dropdown-item.--checked {
    --icon-black:   url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(51, 51, 51)\" d=\"M11.8,14.8L11.8,14.8l-1.1,1.1l-1.1-1.1l-3.2-3.2l1.1-1.1l3.2,3.2l5.7-5.7l1.1,1.1L11.8,14.8z\"/></svg>");
    --icon-grey:    url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(120, 120, 120)\" d=\"M11.8,14.8L11.8,14.8l-1.1,1.1l-1.1-1.1l-3.2-3.2l1.1-1.1l3.2,3.2l5.7-5.7l1.1,1.1L11.8,14.8z\"/></svg>");
    --icon-white:   url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"rgb(250, 250, 250)\" d=\"M11.8,14.8L11.8,14.8l-1.1,1.1l-1.1-1.1l-3.2-3.2l1.1-1.1l3.2,3.2l5.7-5.7l1.1,1.1L11.8,14.8z\"/></svg>");
}

.menu-dropdown-item.--checked::after {
    position: absolute;
    display: block;
    content: var(--icon-black);
    height: 100%;
    width: 1.5rem;
    background-color: #90c8f6;
    top: 0;
    left: 0;
}

.menu-dropdown-item.--checked.--disabled::after {
    content: var(--icon-grey);
    background-color: var(--color-2);
}

@media (max-width: 960px)
{
    .menu-dropdown {
        position: initial;
        border: none;
        background-color: var(--color-7);
        padding: 0;
    }

    .menu-dropdown.--nested {
        margin-left: 12px;
    }

    .menu-dropdown.enter-active, .menu-dropdown.exit-active {
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    .menu-dropdown-item {
        padding: 13px 10px 13px 36px;
        font-size: 15px;
        border-radius: 8px 0 0 8px;
        color: var(--color-1);
        white-space: normal;
        -webkit-tap-highlight-color: transparent;
    }

    .menu-dropdown-item.--divider {
        margin: 2px 10px 2px 18px;
        background-color: var(--color-6);
    }

    /* dont mind me */
    .menu-dropdown-item:hover,
    .menu-dropdown-item:focus,
    .menu-dropdown-item.--disabled:hover,
    .menu-dropdown-item.--disabled:focus,
    .menu-dropdown-item.--menu.--active {
        background-color: var(--color-6);
    }

    .menu-dropdown-item.--menu.--active:focus {
        background-color: var(--color-5);
    }

    .menu-dropdown-item.--menu {
        cursor: pointer;
        -moz-cursor: pointer;
    }

    .menu-dropdown-item.--active {
        overflow: hidden;
    }

    .menu-dropdown-item.--menu::after {
        top: 2px;
        right: 6px;
        content: var(--icon-white);
        transform: scale(1.3);
        display: flex;
        align-items: center;
        transition: transform 0.2s ease-out, right 0.2s ease-out, top 0.2s ease-out;
    }

    .menu-dropdown-item.--menu.--active::after {
        right: 8px;
        top: 1px;
        transform: rotate(90deg) scale(1.3);
    }

    .menu-dropdown-item.--checkbox::after {
        display: block;
        content: "";
        transform: scale(0);
        transition: transform 0.2s ease-out;
    }

    .menu-dropdown-item.--checked::after {
        content: var(--icon-white);
        background-color: transparent;
        display: flex;
        align-items: center;
        top: 3px;
        left: 8px;
        transform: scale(1.3);
    }

    .menu-dropdown-item.--checked.--disabled::after {
        content: var(--icon-grey);
        background-color: transparent;
    }
}