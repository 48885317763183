.canvas-container {
    padding: 40px;
}

.canvas-placeholder, .canvas-container-inner {
    width: 100%;
    height: 100%;
    min-width: 320px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.canvas-container-inner {
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #383838;
    background-color: #535353;
    border-radius: 5px 5px 0 0;
}

.canvas-container-label {
    height: 1.5rem;
    padding: 2px 0 3px 4px;
    box-sizing: border-box;

    font-family: "Lato", sans-serif;
    font-size: 0.9em;
    color: white;
}

canvas {
    flex-grow: 1;
    box-sizing: border-box;
    border: 1px solid #383838;

    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}

.cursorGrabbed {
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
}

.cursorInfo {
    cursor: help;
    cursor: -moz-help;
    cursor: -webkit-help;
}

.cursorCopy {
    cursor: copy;
    cursor: -moz-copy;
    cursor: -webkit-copy;
}

.canvas-placeholder {
    font-family: "Lato", sans-serif;
    font-size: 2em;
    font-weight: 700;
    color: rgba(255,255,255,0.6);
    text-align: center;
    user-select: none;
    -moz-user-select: none;
}

@media (max-width: 960px)
{
    .canvas-container {
        padding: 0;
    }

    .canvas-container-inner {
        min-width: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
    }

    .canvas-container-label {
        height: 0;
        padding: 0;
    }

    canvas {
        border: 0;
        min-height: 1px;
        min-width: 1px;
    }

    .canvas-placeholder {
        min-width: 0;
        word-spacing: 100vw;
        line-height: 4rem;
    }
}